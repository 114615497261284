const content = [
  {
    path: "/users",
    name: "users",
    component: () => import("@/views/content/user/index.vue"),
    meta: {
      auth: true,
      pageTitle: "User",
      breadcrumb: [
        {
          text: "User",
          active: true,
        },
      ],
    },
  },
  {
    path: "/create-users",
    name: "create-users",
    component: () => import("@/views/content/user/create.vue"),
    meta: {
      auth: true,
      pageTitle: "Create User",
      navActiveLink: "users",
      breadcrumb: [
        {
          text: "Create User",
          active: true,
        },
      ],
    },
  },
  {
    path: "/edit-users/:id",
    name: "edit-users",
    component: () => import("@/views/content/user/edit.vue"),
    meta: {
      auth: true,
      pageTitle: "Edit User",
      navActiveLink: "users",
      breadcrumb: [
        {
          text: "Edit User",
          active: true,
        },
      ],
    },
  },
  {
    path: "/interviews",
    name: "interviews",
    component: () => import("@/views/content/interview/index.vue"),
    meta: {
      auth: true,
      pageTitle: "Interview",
      breadcrumb: [
        {
          text: "Interview",
          active: true,
        },
      ],
    },
  },
  {
    path: "/providers",
    name: "providers",
    component: () => import("@/views/content/providers/index.vue"),
    meta: {
      auth: true,
      pageTitle: "Provider",
      breadcrumb: [
        {
          text: "Provider",
          active: true,
        },
      ],
    },
  },
  {
    path: "/detail-provider/:id",
    name: "detail-provider",
    component: () => import("@/views/content/providers/detail.vue"),
    meta: {
      auth: true,
      pageTitle: "Detail Provider",
      navActiveLink: "providers",
      breadcrumb: [
        {
          text: "Detail Provider",
          active: true,
        },
      ],
    },
  },
  {
    path: "/edit-providers/:id",
    name: "edit-providers",
    component: () => import("@/views/content/providers/edit.vue"),
    meta: {
      auth: true,
      pageTitle: "Edit Provider",
      navActiveLink: "providers",
      breadcrumb: [
        {
          text: "Edit Provider",
          active: true,
        },
      ],
    },
  },
  {
    path: "/withdrawal",
    name: "withdrawal",
    component: () => import("@/views/content/withdrawal/index.vue"),
    meta: {
      auth: true,
      pageTitle: "Withdrawal",
      breadcrumb: [
        {
          text: "Withdrawal",
          active: true,
        },
      ],
    },
  },
  {
    path: "/update-withdrawal/:id",
    name: "update-withdrawal",
    component: () => import("@/views/content/withdrawal/update.vue"),
    meta: {
      auth: true,
      pageTitle: "Update Withdrawal",
      navActiveLink: "withdrawal",
      breadcrumb: [
        {
          text: "Update Withdrawal",
          active: true,
        },
      ],
    },
  },
  {
    path: "/business-group",
    name: "business-group",
    component: () => import("@/views/content/business-group/index.vue"),
    meta: {
      auth: true,
      pageTitle: "Business Group",
      breadcrumb: [
        {
          text: "Business Group",
          active: true,
        },
      ],
    },
  },
  {
    path: "/update-business-group/:id",
    name: "update-business-group",
    component: () => import("@/views/content/business-group/update.vue"),
    meta: {
      auth: true,
      pageTitle: "Update Business Group",
      navActiveLink: "business-group",
      breadcrumb: [
        {
          text: "Update Business Group",
          active: true,
        },
      ],
    },
  },
  {
    path: "/create-business-group",
    name: "create-business-group",
    component: () => import("@/views/content/business-group/create.vue"),
    meta: {
      auth: true,
      pageTitle: "Create Business Group",
      navActiveLink: "business-group",
      breadcrumb: [
        {
          text: "Create Business Group",
          active: true,
        },
      ],
    },
  },
  {
    path: "/transaction",
    name: "transaction",
    component: () => import("@/views/content/transaction/index.vue"),
    meta: {
      auth: true,
      pageTitle: "Transaction",
      breadcrumb: [
        {
          text: "Transaction",
          active: true,
        },
      ],
    },
  },
  {
    path: "/refund-transaction/:id",
    name: "refund-transaction",
    component: () => import("@/views/content/transaction/refund.vue"),
    meta: {
      auth: true,
      pageTitle: "Refund Transaction",
      navActiveLink: "transaction",
      breadcrumb: [
        {
          text: "Refund Transaction",
          active: true,
        },
      ],
    },
  },
  {
    path: "/location",
    name: "location",
    component: () => import("@/views/content/location/index.vue"),
    meta: {
      auth: true,
      pageTitle: "Location",
      breadcrumb: [
        {
          text: "Location",
          active: true,
        },
      ],
    },
  },
  {
    path: "/create-location",
    name: "create-location",
    component: () => import("@/views/content/location/create.vue"),
    meta: {
      auth: true,
      pageTitle: "Create Location",
      navActiveLink: "location",
      breadcrumb: [
        {
          text: "Create Location",
          active: true,
        },
      ],
    },
  },
  {
    path: "/update-location/:id",
    name: "update-location",
    component: () => import("@/views/content/location/update.vue"),
    meta: {
      auth: true,
      pageTitle: "Update Location",
      navActiveLink: "location",
      breadcrumb: [
        {
          text: "Update Location",
          active: true,
        },
      ],
    },
  },
  {
    path: "/category",
    name: "category",
    component: () => import("@/views/content/category/index.vue"),
    meta: {
      auth: true,
      pageTitle: "Category",
      breadcrumb: [
        {
          text: "Category",
          active: true,
        },
      ],
    },
  },
  {
    path: "/update-category/:id",
    name: "update-category",
    component: () => import("@/views/content/category/update.vue"),
    meta: {
      auth: true,
      pageTitle: "Update Category",
      navActiveLink: "category",
      breadcrumb: [
        {
          text: "Update Category",
          active: true,
        },
      ],
    },
  },
  {
    path: "/create-category",
    name: "create-category",
    component: () => import("@/views/content/category/create.vue"),
    meta: {
      auth: true,
      pageTitle: "Create Category",
      navActiveLink: "category",
      breadcrumb: [
        {
          text: "Create Category",
          active: true,
        },
      ],
    },
  },
  {
    path: "/sub-category",
    name: "sub-category",
    component: () => import("@/views/content/sub-category/index.vue"),
    meta: {
      auth: true,
      pageTitle: "Sub Category",
      breadcrumb: [
        {
          text: "Sub Category",
          active: true,
        },
      ],
    },
  },
  {
    path: "/update-sub-category/:id",
    name: "update-sub-category",
    component: () => import("@/views/content/sub-category/update.vue"),
    meta: {
      auth: true,
      pageTitle: "Update Sub Category",
      navActiveLink: "sub-category",
      breadcrumb: [
        {
          text: "Update Sub Category",
          active: true,
        },
      ],
    },
  },
  {
    path: "/create-sub-category",
    name: "create-sub-category",
    component: () => import("@/views/content/sub-category/create.vue"),
    meta: {
      auth: true,
      pageTitle: "Create Sub Category",
      navActiveLink: "sub-category",
      breadcrumb: [
        {
          text: "Create Sub Category",
          active: true,
        },
      ],
    },
  },
  {
    path: "/notification",
    name: "notification",
    component: () => import("@/views/content/notification/index.vue"),
    meta: {
      auth: true,
      pageTitle: "Notification",
      breadcrumb: [
        {
          text: "Notification",
          active: true,
        },
      ],
    },
  },
  {
    path: "/update-notification/:id",
    name: "update-notification",
    component: () => import("@/views/content/notification/update.vue"),
    meta: {
      auth: true,
      pageTitle: "Update Notification",
      navActiveLink: "notification",
      breadcrumb: [
        {
          text: "Update Notification",
          active: true,
        },
      ],
    },
  },
  {
    path: "/customer-bypass",
    name: "customer-bypass",
    component: () => import("@/views/content/customer-bypass/index.vue"),
    meta: {
      auth: true,
      pageTitle: "Customer Bypass",
      breadcrumb: [
        {
          text: "Customer Bypass",
          active: true,
        },
      ],
    },
  },
];

export default content;
